import 'magnific-popup';
import 'slick-carousel';
//SPドロップダウンメニュー
jQuery(function ($) {
  // 検索フォームでのキーダウンイベント
  $('form[name="search_form"]').on('keydown', function (e) {
    // ボタンがaタグなのでキーダウンイベントでEnterが押下された場合、ボタンクリックイベント発火
    if (e.keyCode === 13) {
      $('.search').trigger('click');
    }
  });

  // 検索ボタンクリックイベント
  $('.search').on('click', function () {
    $('form').submit();
  });

  // 表示件数チェンジイベント
  $('.page_count').on('change', function () {
    location.href = $(this).find('option:selected').data('url');
  });

  // ソート条件チェンジイベント
  $('.sort_criteria').on('change', function () {
    location.href = $(this).find('option:selected').data('url');
  });

  $('.menu_open').click(function () {
    if ($('.sp nav').css('display') === 'block') {
      $('.close_bar').hide();
      $('.open_bar').show();
      $('.sp nav').slideUp('1500');
    } else {
      $('.open_bar').hide();
      $('.close_bar').show();
      $('.sp nav').slideDown('1500');
    }
  });
  $('.close').click(function () {
    $('.close_bar').hide();
    $('.open_bar').show();
    $('.sp nav').slideUp('1500');
  });

  // タブ処理
  $(document).on('click', ".tab li", function () {
    var index = $('.tab li').index(this);
    $('.tab li').removeClass('active');
    $(this).addClass('active');
    $('.job_inner ul').removeClass('show').eq(index).addClass('show');
    $('.login_inner').removeClass('show').eq(index).addClass('show');
    $('.popup_area ul').removeClass('show').eq(index).addClass('show');
    $('.work_article_link').addClass('hide').eq(index).removeClass('hide');
    if ($(window).width() < 750) {
      //$('.tab').css('display','none');
      //$('a.back').css('display','inline-block');
      //$('.show').css('display','block');
    } else {
      $('.tab li').removeClass('active');
      $(this).addClass('active');
    }
  });


  $(".tab_inner [class^='page']:not(:first)").addClass('hide');
  $('.js-tab li').click(function () {
    var index = $('.tab li').index(this);
    $(".tab_inner [class^='page']").addClass('hide');
    $('.tab_inner .page' + (index + 1)).removeClass('hide');
  });

  // チェック状態時に背景色を変更する
  $('.popup_area input:checked').closest('li').addClass('checked');
  $(".popup_area input[type='checkbox']").change(function () {
    if ($(this).is(":checked")) {
      $(this).closest('li').addClass('checked');
    } else {
      $(this).closest('li').removeClass('checked');
    }
  });
});

// pagetop
jQuery(function ($) {
  var $pageTop = $("#pagetop")
  $pageTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 500) {
      $pageTop.fadeIn();
    } else {
      $pageTop.fadeOut();
    }
  });
  $('a[href^="#"]').click(function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, 500, "swing");
    return false;
  });
});

//文字省略処理
jQuery(function ($) {
  $(".job_inner a").each(function () {
    if ($(this).text().length > 32) {
      $(this).text($(this).text().substr(0, 32));
      $(this).append('...');
    }
  });

  $(".omitted").each(function () {
    if ($(this).text().length > $(this).data('length')) {
      $(this).text($(this).text().substr(0, $(this).data('length') - 3));
      $(this).append('...');
    }
  });

});

jQuery(function ($) {
  if ($(window).width() < 750) {
    $(".interact_inner .cont_area a").each(function () {
      if ($(this).text().length > 33) {
        $(this).text($(this).text().substr(0, 30));
        $(this).append('...');
      }
    });
  } else {
    $(".interact_inner .cont_area a").each(function () {
      if ($(this).text().length > 21) {
        $(this).text($(this).text().substr(0, 21));
        $(this).append('...');
      }
    });
  }
});

jQuery(function ($) {
  if ($(window).width() < 750) {
    $(".blog_inner a").each(function () {
      if ($(this).text().length > 22) {
        $(this).text($(this).text().substr(0, 19));
        $(this).append('...');
      }
    });
  } else {
    $(".blog_inner a").each(function () {
      if ($(this).text().length > 20) {
        $(this).text($(this).text().substr(0, 17));
        $(this).append('...');
      }
    });
  }
});

// jQuery(function ($) {
//   if ($(window).width() < 750) {
//     $("#job_area .txt_area").each(function () {
//       if ($(this).text().length > 60) {
//         $(this).text($(this).text().substr(0, 57));
//         $(this).append('...');
//       }
//     });
//   }
// });

jQuery(function ($) {
  if ($(window).width() < 750) {
    $("header .sp .name span").each(function () {
      if ($(this).text().length > 10) {
        $(this).text($(this).text().substr(0, 7));
        $(this).append('...');
      }
    });
  }
});

jQuery(function ($) {
  if ($(window).width() < 750) {
    $("#job_search_area .cont_area a").each(function () {
      if ($(this).text().length > 26) {
        $(this).text($(this).text().substr(0, 24));
        $(this).append('...');
      }
    });

    $("#job_search_area .cont_area .box_l p:not(.text-overflow)").each(function () {
      if ($(this).text().length > 48) {
        $(this).text($(this).text().substr(0, 46));
        $(this).append('...');
      }
    });

    // safariだと全角数字がリンクになるので、取得したテキスト内容を再度設定
    $("#job_search_area .cont_area .box_l .text-overflow").each(function () {
      $(this).text($(this).text())
    });
  }
});

// FAQ
jQuery(function ($) {
  $("#faq_area dt").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass('up');
  });
});

// mypage
jQuery(function ($) {
  $("#mypage .sub h2").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass('up');
  });
});

jQuery(function ($) {
  /**
   * windowスクロール
   */
  $(window).on('scroll', function () {
    if (navigator.userAgent.match(/(iPhone|iPod|Android)/i) || $(window).width() < 750) {
      // スマホ・タブレット（iOS・Android）の場合
      return;
    }
    var fiexed_flag;
    if ($('header > nav')[0]) {
      fiexed_flag = $('header > nav').offset().top + $('header > nav').height() < $(window).scrollTop();
      if (fiexed_flag && $('#fixed_header').css('display') != 'block') {
        $('#fixed_header').css('display', 'block');
      } else if (!fiexed_flag && $('#fixed_header').css('display') != 'none') {
        $('#fixed_header').css('display', 'none');
      }

      if ($('#fixed_header').css('display') == 'block' && ($(window).scrollLeft() * -1) != $('#fixed_header').css('left')) {
        $('#fixed_header').css('left', $(window).scrollLeft() * -1);
      }
    }
  });
});

// 職人・事業所検索
jQuery(function ($) {
  $(".search_list dt.detail_search_dt, .search_list dt.detail_search_dt .btn1").on("click", function () {
    if ($(this).hasClass('searched')) {
      return;
    }

    if ($(this).text() == '検索条件を閉じる') {
      $(this).text('検索条件を変更する');
    } else if ($(this).text() == '検索条件を変更する') {
      $(this).text('検索条件を閉じる');
    }

    $('.search_list dd.detail_search_dd').slideToggle();
    $('.search_list dt.detail_search_dt').toggleClass('up');
  });
});

// 検索
jQuery(function ($) {
  $(".search_conditions_switch").on("click", function () {
    if ($('.main_box').hasClass('up')) {
      $('.pc .search_conditions_switch').text('絞り込み条件を閉じる');
    } else {
      $('.pc .search_conditions_switch').text('絞り込み条件を表示する');
    }

    $('.main_search').slideToggle();
    $('.main_box').toggleClass('up');
  });
});

// 行クリック
jQuery(function ($) {
  $('tr[data-href]').addClass('clickable')
    .click(function (e) {
      if (!$(e.target).is('a')) {
        window.location = $(e.target).closest('tr').data('href');
      }
    });
});

// 行クリック
jQuery(function ($) {
  $("#mv_area .mv_inner .iframe_link img").on("click", function () {
    $('#mypage .mfp-iframe-holder .mfp-content').height(640);
  });
});

jQuery(function ($) {
  // マイ条件として保存するボタンクリック
  $('.save_mysearch').click(function () {
    $('.save_mysearch').addClass('btn_disabled');

    $.ajax({
      type: 'POST',
      url: '/create_mysearch',
      data: $('#search_form').serialize(),
      dataType: "json",
      timespan: 1000,

    }).done(function (res) {

      if (res.result == 'OK') {
        $('#message').html(res.message);
      } else {
        $('#message').html('マイ検索条件を登録できませんでした。');
      }

      $('.save_mysearch').removeClass('btn_disabled');
    }).fail(function (res) {
      $('.message_area').removeClass('hide');
      $('#message').html('エラーが発生しました。');

    }).always(function (res) {
      $('.message_area').removeClass('hide');
      scrollTo(0, 0);
    })
  });
});

jQuery(function ($) {
  // ブログ記事プレビュー画面 編集画面に戻るボタン
  $("#previous_form").on("click", function () {
    $("#update_blog_article").attr("action", "/edit_blog_article").submit();
  });
});

// Ph3-2対応追加
$(function(){
  // MV スライド ============
  $('.top_mv_slider').slick({
    autoplay:true,
    autoplaySpeed: 3000,
    dots: true,
    infinite:true,
    fade:true,
    speed: 1000,
    arrows: false,
  });

// お仕事しませんか？ ============
// 頼んでみませんか？ スライド
  $('.top_job_items').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    centerMode: true,
    centerPadding: '250px',
    variableWidth: true,
    arrows: true,
    prevArrow: '<button type="button" class="slide-arrow prev-arrow"></button>',
    nextArrow: '<button type="button" class="slide-arrow next-arrow"></button>',
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          centerPadding: '30px',
        },
      },
    ],
  });
  // top_job_works
  $('#top_job_works .top_job_items').on('beforeChange',function(){
    $("#top_job_works .slick-slide").addClass("is-opacity");
  });
  $('#top_job_works .top_job_items').on('afterChange',function(){
    $("#top_job_works .slick-slide").removeClass("is-opacity");
  });
  // top_job_ask
  $('#top_job_ask .top_job_items').on('beforeChange',function(){
    $("#top_job_ask .slick-slide").addClass("is-opacity");
  });
  $('#top_job_ask .top_job_items').on('afterChange',function(){
    $("#top_job_ask .slick-slide").removeClass("is-opacity");
  });

  // ブログ ============
  // pc
  $('.top_blog_tab_menu li').on('click tap', function() {
    var tabmenu = $(this).parents('.top_blog_tab_menu');
    var datatab = $(tabmenu).data('tab');
    var tabpanel = $('#' + datatab);
    var index = $(this).index();

    $('ul[data-tab='+ datatab +']').each(function() {
      $(this).children().removeClass('selected');
      $(this).children().eq(index).addClass('selected');
    });

    $('select[data-tab='+ datatab +']').each(function() {
      $(this).prop('selectedIndex', index);
    });

    tabpanel.children().removeClass('is-show');
    tabpanel.children().eq(index).addClass('is-show');
  });

  // sp
  $('.top_blog_select label').on('change', function() {
    var tabmenu = $(this).parents('.top_blog_select');
    var datatab = $(tabmenu).data('tab');
    var tabpanel = $('#' + datatab);
    var index = $(this).index();

    $('label[data-tab='+ datatab +']').each(function() {
      $(this).children().removeClass('selected');
      $(this).children().eq(index).addClass('selected');
    });

    $('label[data-tab='+ datatab +']').each(function() {
      $(this).prop('selectedIndex', index);
    });

    tabpanel.children().removeClass('is-show');
    tabpanel.children().eq(index).addClass('is-show');
  });
});